import { React } from "../../../common";
import { PazaMarket } from "../../components";

const Component = () => {
    return (

        <div className="pazamarketContainer">
            <div className="widthfix_10px">
                <div className="pmHeadLn1">PAZA Markets</div>
                <div className="pmHeadLn2">For Multi-Vertical Centralized & Decentralized Financial Markets</div>
                <PazaMarket />
            </div>
        </div>
    )
}

export default Component;