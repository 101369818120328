import { React } from "../../common";
import Container from "../container";

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className="commonPageHeader">
                            <div className="pgHeadText">Privacy Policy</div>
                            {/* <div className="pgHeadDescText">&nbsp;</div> */}
                        </div>
                        <div className="widthfix_10px">
                            <div className="genericPgBlk">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;