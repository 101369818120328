import { React } from "react-router-dom";

const Component = () => {
    return (
        <div>
            <ul className="pm_ver_col">
                <li className="pm_ver_col">
                    <div className="pmIcon1"></div>
                    <div className="pmTextHead">Loans</div>
                    <div className="pmTextDesc">Originate loans, refinance, buy & sell</div>
                </li>
                <li className="pm_ver_col"></li>
                <li className="pm_ver_col">
                    <div className="pmIcon2"></div>
                    <div className="pmTextHead">Servicing</div>
                    <div className="pmTextDesc">Near real-time status of the loan, gamified for borrowers & servicers</div>
                </li>
                <li className="pm_ver_col"></li>
                <li className="pm_ver_col">
                    <div className="pmIcon3"></div>
                    <div className="pmTextHead">RWA</div>
                    <div className="pmTextDesc">Loan & loan-pool NFTs transacted by primary, secondary market participants</div>
                </li>
                <li className="pm_ver_col"></li>
                <li className="pm_ver_col">
                    <div className="pmIcon4"></div>
                    <div className="pmTextHead">Marketplace</div>
                    <div className="pmTextDesc">Bonds, Funds, Exchanges & configurable home-related services</div>
                </li>
                <li className="pm_ver_col"></li>
                <li className="pm_ver_col">
                    <div className="pmIcon5"></div>
                    <div className="pmTextHead">Money Markets</div>
                    <div className="pmTextDesc">Repos & CDs</div>
                </li>
            </ul>
        </div>
    )
}

export default Component;