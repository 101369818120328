import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../screens/home";
import Market from "../screens/headertabs/market"
import About from "../screens/headertabs/about"
import Leadership from "../screens/headertabs/leadership"
import Contact from "../screens/headertabs/contact"
import Privacy from "../screens/privacy";
import Terms from "../screens/terms";
import Download from "../screens/download";

const RouteLinks = [
  { id: 1, path: "", Component: Home },
  { id: 2, path: "market", Component: Market },
  { id: 3, path: "about", Component: About },
  { id: 4, path: "leadership", Component: Leadership },
  { id: 5, path: "contact", Component: Contact },
  { id: 6, path: "privacy", Component: Privacy },
  { id: 7, path: "terms", Component: Terms },
  { id: 8, path: "download", Component: Download },
];

function PrivateRoute({ children }) {
  let loggedin = sessionStorage.getItem("user");
  return loggedin ? children : <Navigate to="/" />;
}

const RendorRoutes = () => {
  return (
    <Routes>
      {RouteLinks.map((x) => {
        if (x.Session) {
          return <Route key={x.id} path={"/" + x.path} exact
            element={
              <PrivateRoute>
                <x.Component />
              </PrivateRoute>
            }
          />
        } else {
          return <Route key={x.id} path={"/" + x.path} exact
            element={<x.Component />} />
        }
      })}
    </Routes>
  );
};

export default RendorRoutes;