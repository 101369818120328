import { React, Link } from "../../common";
import Container from "../container";

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className="commonPageHeader">
                            <div className="pgHeadText">discover</div>
                            <div className="pgHeadDescText">A STRONG THOUGHT LEADERSHIP STRATEGY FROM INDUSTRY LEADERS</div>
                        </div>
                        <div className="widthfix_10px">
                            <div className="download">
                                <ul className="downloadIIIcol">
                                    <li className="downloadIIIcol">
                                        <div className="downloadImg01"></div>
                                        <div className="downloadContentBlk">
                                            <div className="downloadBlkHead">
                                                <Link to="https://managementrethink.isb.edu/en/Topics-Mgmt-Rethink/inspire--innovate--iterate--the-keys-to-transforming-businesses-/managing-ecosystems-the-path-to-sustainable-prosperity.html" target="_blank">Managing Ecosystems-The Path to Sustainable Prosperity</Link>
                                            </div>
                                            <div className="downloadBlkText">Ecosystems are indisdiscriminatingly nurturing, both in real and virtual worlds. An organization looking to be close to their customers needs to transcend beyond their traditional supply chains into their ecosystems.</div>
                                            <div className="downloadAuthor">Author</div>
                                            <div className="downloadAuthor"><b>Alok K Sinha</b></div>
                                            <div className="downloadAuthor">Chairman and Chief Ecosystems Officer</div>
                                        </div>
                                    </li>
                                    <li className="downloadIIIcol">
                                        <div className="downloadImg03"></div>
                                        <div className="downloadContentBlk">
                                            <div className="downloadBlkHead">
                                                <Link to="https://ispmplprodeu.azureedge.net/cdnimages/country-as-platform.pdf" target="_blank">Country-as-a-Platform - Digital Cosmos & Istakapaza : Transforming 'electronic-Commerce' into 'Ecosystem-commerce'</Link>
                                            </div>
                                            <div className="downloadBlkText">An innocuous meeting with an ertwhile dignitary set into motion the creation of a platform that even we had not fathomed of its potential impact.</div>
                                            <div className="downloadAuthor">Author</div>
                                            <div className="downloadAuthor"><b>Alok K Sinha</b></div>
                                            <div className="downloadAuthor">Chairman and Chief Ecosystems Officer</div>
                                        </div>
                                    </li>
                                    <li className="downloadIIIcol">
                                        <div className="downloadImg04"></div>
                                        <div className="downloadContentBlk">
                                            <div className="downloadBlkHead">
                                                <Link to="https://ispmplprodeu.azureedge.net/cdnimages/Litepaper.pdf" target="_blank">Litepaper : CeDeFi-enabled Home Mortgage Ecosystem</Link>
                                            </div>
                                            <div className="downloadBlkText">A unique and transformational combination of both private and public blockchain components that orchestrates the primary and secondary mortgage ecosystem.</div>
                                            <div className="downloadAuthor">Author</div>
                                            <div className="downloadAuthor"><b>Alok K Sinha</b></div>
                                            <div className="downloadAuthor">Chairman and Chief Ecosystems Officer</div>
                                        </div>
                                    </li>
                                    <li className="downloadIIIcol">
                                        <div className="downloadImg05"></div>
                                        <div className="downloadContentBlk_minH160">
                                            <div className="downloadBlkHead">
                                                <Link to="https://www.linkedin.com/posts/sinhaalokk_trust-activity-6793447127461822464-VENl/?utm_source=share&utm_medium=member_desktop" target="_blank">Dr. Philip Kotler and Christian Sarkar's positioning of Trust as the currency of future marketplaces with Istakapaza championing this ahead of all known marketplaces platforms.</Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="downloadIIIcol">
                                        <div className="downloadImg02"></div>
                                        <div className="downloadContentBlk_minH160">
                                            <div className="downloadBlkHead">
                                                <Link to="https://thinkers50.com/power-of-ecosystems/" target="_blank">A Framework for Regeneration – Christian Sarkar & Philip Kotler (Istakapaza quoted on page 173)</Link>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;