import { React } from "../../../common";
const Component = () => {
    return (
        <div id="home_bottom_blk">
            <div className="widthfix_10px">
                <ul className="home_bot_ver_col">
                    <li className="home_bot_ver_col">
                        <div className="botIcon_blk">
                            <div className="botIcon1"></div>
                        </div>
                        <div className="botTextHead">200M</div>
                        <div className="botTextDesc">Total Funded Value</div>
                    </li>
                    <li className="home_bot_ver_col"></li>
                    <li className="home_bot_ver_col">
                        <div className="botIcon_blk">
                            <div className="botIcon2"></div>
                        </div>
                        <div className="botTextHead">350</div>
                        <div className="botTextDesc">Number of Loan NFT's</div>
                    </li>
                    <li className="home_bot_ver_col"></li>
                    <li className="home_bot_ver_col">
                        <div className="botIcon_blk">
                            <div className="botIcon3"></div>
                        </div>
                        <div className="botTextHead">100M</div>
                        <div className="botTextDesc">Number of PAZA Tokens</div>
                    </li>
                    {/* <li className="home_bot_ver_col"></li>
                    <li className="home_bot_ver_col">
                        <div className="botIcon_blk">
                            <div className="botIcon4"></div>
                        </div>
                        <div className="botTextHead">$ 26.59B</div>
                        <div className="botTextDesc">Volume of Stable Coins</div>
                    </li>
                    <li className="home_bot_ver_col"></li>
                    <li className="home_bot_ver_col">
                        <div className="botIcon_blk">
                            <div className="botIcon5"></div>
                        </div>
                        <div className="botTextHead">$ 1.23T</div>
                        <div className="botTextDesc">Global Crypto Market</div>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}

export default Component;