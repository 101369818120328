import { React, Header, Footer } from "../../common";

import {
  HomeVideo, HomeViewBottom, PAZAMarket, Technology, News,
} from "./childs";

const Component = () => {
  return (
    <>
      <Header type={"PreLogin"} />

      {/*Home Video*/}
      <HomeVideo />

      {/*Homepage Bottom*/}
      <HomeViewBottom />

      {/*PAZA MArket*/}
      <PAZAMarket />

      {/* Technology */}
      <Technology />

      {/* News */}
      <News />

      {/*Footer*/}
      <Footer type={"Full"} />
    </>
  );
};

export default Component;
