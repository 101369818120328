import { React } from "../../common";
import Container from "../container";

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className="commonPageHeader">
                            <div className="pgHeadText">Terms of Use</div>
                            {/* <div className="pgHeadDescText">&nbsp;</div> */}
                        </div>
                        <div className="widthfix_10px">
                            <div className="genericPgBlk">
                                <div className="txtBoldCaps">RIGHT TO SELECT OR REPLACE ECOSYSTEM PARTNERS</div>
                                <div className="pgTxtNormal">Istakapaza’s HOME MORTGAGE Ecosystem Commerce Platform and Services Offerings means, electronic portals (ISPZ-HMEC), through which customers will list and update regularly, and provide and/or access information in relation to the sale of their Home and Home Mortgage related Products and Services. The ISPZ-HMEC platform will be provided centrally by Istakapaza Inc. (ISPZ), and it will engage with other ecosystem technology partners to service the end-to-end needs as per the defined scope, for e.g. the DEFI platform(s), Cloud infrastructure providers, banking and payments integration etc. ISPZ retains the right to select or replace such ecosystem partners at any point in time.</div>
                                <div className="txtBoldCaps">RIGHT TO AUCTION THE STAKING CONTRACT BETWEEN BORROWER AND A LENDER</div>
                                <div className="pgTxtNormal">Following terms are embedded in the Staking Smart Contract:</div>
                                <div className="pgTxtNormal">“A staking contract will be created between you, the “lender”, and your customer “borrower” at the end of a successfully funded loan closure. Out of the total fees to be paid to the ISPZ platform, 2 BPS of the loan value will be used as the initial amount for the staking contract. This is deemed to be a payback to the borrower by you and acceptable under Respa guidelines. This staking contract is a promissory signed by you to provide a certain cash discount in USD or in BPS of the loan value when the same borrower returns for a refinance or a new purchase transaction with you at a subsequent point in time. The staked contract will be unstaked when you pay the promised amount to the smart contract and the money will be available to the borrower to set off his costs against the closing fees or any other service that he can avail via you for the refinance or the new purchase transaction."</div>
                                <div className="pgTxtNormal">PLEASE READ THE FOLLOWING CLAUSES CAREFULLY. THEY ARE TO BE READ CONCURRENTLY WITH THE ABOVE SECTION.</div>
                                <ul className="bulletPoints_un">
                                    <li className="bp_circleOutline">If you, as a lender, decide not to honor the staking contract, for any reason, the Platform reserves the right to auction the contract to another willing lender. This includes any level of lender default.</li>
                                    <li className="bp_circleOutline">In a case where the borrower needs a product that cannot be offered by you, the Platform can, for a small fee, transfer the contract to a new lender at your request.</li>
                                    <li className="bp_circleOutline">When the borrower requests for a refinance and a subsequent unstacking of the contract, you will need to respond to the request from the borrower within 15 days. After the 15 days pass, the contract will automatically go to auction.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;