import { React } from "../../../common";
import Container from "../../container";

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className="commonPageHeader">
                            <div className="pgHeadText">PIONEERS IN HOME MORTGAGE</div>
                            <div className="pgHeadDescText">ECOSYSTEM WITH BLOCKCHAIN TECHNOLOGY</div>
                        </div>
                        <div className="widthfix_10px">
                            <div className="about">
                                <div className="aboutBlkTopTxt">We have harnessed blockchain technology and smart contracts to effect 50% time reduction in closing and 200-250 bps savings across the cycle.</div>
                                <ul className="aboutIIcol">
                                    <li className="aboutIIcol">
                                        <div className="aboutBlkHeadText">Mission</div>
                                        <div className="aboutBlkText">Enabling a true B2B2C SaaS Platform seeded with Financial Services & Automotive Marketplaces</div>
                                    </li>
                                    <li className="aboutIIcol">
                                        <div className="aboutBlkHeadText">Vision</div>
                                        <div className="aboutBlkText">To be with the borrower through the entire journey of home purchase, subsequent refinancing and home services. Add much needed efficiencies, transparency and liquidity to the home mortgage ecosystem via trustable & real-time data.</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;