import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div className="widthfix_10px">
            <div className="newsContainer">
                <div className="newsHeadLn1">NEWS & RESOURCES</div>
                <ul className="newsHeadLn_More_Blk">
                    <li className="newsHeadLn2">
                        Read Latest News & Resources <br />From Our Blog
                    </li>
                    <li className="newsMoreBtnBlk">
                        <Link to="/download">
                            <div className="readMoreBlk">
                                <div className="readmoreText">Read More</div>
                                <div className="readMoreCircle"></div>
                            </div>
                        </Link>
                    </li>
                </ul>
                <ul className="news_ver_col">
                    <li className="news_ver_col">
                        <Link to="https://cryptoexpodubai.com/#Speakers" target="_blank" title="Dubai Expo"><div className="newsMainImg"></div></Link>
                        <div className="newsMainImgDescBlk">
                            <div className="newsMainHeadDate">Blockchain expo dubai</div>
                            <div className="newsMainHeadDateText">20 - 21 SEPTEMBER 2023</div>
                            <div className="mainImgTextHead">Meet us at Crypto Expo Dubai 2023</div>
                            <div className="mainImgTextDesc">To be with the borrower through the entire journey of home purchase, subsequent refinancing and home services. Add the much needed efficiencies, transparency and liquidity to the home mortgage ecosystem via trustable & real-time data.</div>
                        </div>
                    </li>
                    <li className="news_ver_col">
                        <ul className="subImgTxtBlk">
                            <li className="subImgTxtBlk">
                                <div className="newsSubImg01"></div>
                            </li>
                            <li className="subImgTxtBlk">
                                <div className="subNoteText">Resources</div>
                                <Link to="https://managementrethink.isb.edu/en/Topics-Mgmt-Rethink/inspire--innovate--iterate--the-keys-to-transforming-businesses-/managing-ecosystems-the-path-to-sustainable-prosperity.html" target="_blank"><div className="subHeadText">Managing Ecosystems - The Path to Sustainable Prosperity</div></Link>
                                <div className="subDescText">Ecosystems are indisdiscriminatingly nurturing, both in real and virtual worlds. An organization looking to be close to...</div>

                            </li>
                        </ul>

                        <ul className="subImgTxtBlk">
                            <li className="subImgTxtBlk">
                                <div className="newsSubImg02"></div>
                            </li>
                            <li className="subImgTxtBlk">
                                <div className="subNoteText">Resources</div>
                                <Link to="https://ispmplprodeu.azureedge.net/cdnimages/country-as-platform.pdf" target="_blank"><div className="subHeadText">Community-as-a-Platform-Digital Cosmos & Istakapaza</div></Link>
                                <div className="subDescText">An innocuous meeting with an ertwhile dignitary set into motion the creation of a platform that even we had not fathomed of its potential impact...</div>

                            </li>
                        </ul>

                        <ul className="subImgTxtBlk">
                            <li className="subImgTxtBlk">
                                <div className="newsSubImg03"></div>
                            </li>
                            <li className="subImgTxtBlk">
                                <div className="subNoteText">Resources</div>
                                <Link to="https://thinkers50.com/power-of-ecosystems/" target="_blank"><div className="subHeadText">A Framework for Regeneration - Christian Sarkar & Philip Kotler</div></Link>
                                <div className="subDescText">Ecosystems are indisdiscriminatingly nurturing, both in real and virtual worlds. An organization looking to be close to...</div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Component;