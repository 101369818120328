import { Link } from "react-router-dom";

const FooterFull = () => {
    return (
        <div id="footer_home">
            <div className="widthfix_10px">
                <ul className="footerVerCol">
                    <li className="footerVerCol">
                        <div className="ipazalabsWhiteLogo footerLogoSetting"></div>
                        <div className="footerCopyright">iPaza Labs &copy; {new Date().getFullYear()} | All rights reserved.</div>
                        <div className="footerTerms"><Link to="/terms" className="termsLink">Terms of Use</Link>&nbsp; | &nbsp;<Link to="/privacy" className="termsLink">Privacy Policy</Link></div>
                    </li>
                    <li className="footerVerCol">
                        <div className="footerHeadText">Contact</div>
                        <div className="footerContact">1900 Boothe Cir., Suite 100 - B<br />Longwood FL -32750</div>
                        <ul className="footerContTxtIconBlk">
                            <li className="footerContTxtIconBlk"><div className="fotPhIcon"></div></li>
                            <li className="footerContTxtIconBlk">+1 (201) 921-8171 <br />+1 (321) 400-8329</li>
                            <li className="footerContTxtIconBlk"><div className="fotMailIcon"></div></li>
                            <li className="footerContTxtIconBlk">info@ipaza.io</li>
                        </ul>
                    </li>
                    <li className="footerVerCol">
                        <div className="footerHeadText">Quick Links</div>
                        <ul className="footerQuickLink">
                            <li className="footerQuickLink"><Link to="/about" className="quickLink">About Us</Link></li>
                            <li className="footerQuickLink"><Link to="/leadership" className="quickLink">Leadership</Link></li>
                            <li className="footerQuickLink"><Link to="/download" className="quickLink">Downloads</Link></li>
                            <li className="footerQuickLink"><Link to="/contact" className="quickLink">Contact Us</Link></li>
                        </ul>
                    </li>
                    <li className="footerVerCol">
                        <div className="footerHeadText">Get Updates</div>
                        <div className="footerGetUpdate">We'll keep you posted on rates, mortgage news, home tips and other  great offers</div>
                        <div className="fotterGetUpdateFormBlk">
                            <input type="email" placeholder="Your E-mail" />
                            <Link to="#"><div className="footerBtnBlk"></div></Link>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    );
}

const FooterSmall = () => {
    return (
        <div id="footer">
            <div className="footer_blk">
                <div className="widthfix">
                    <div className="footerInpage">
                        iPazalabs &copy; {new Date().getFullYear()} | All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    );
}

const Component = ({ type }) => {
    return type ? <FooterFull /> : <FooterSmall />
};

export default Component;