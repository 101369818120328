import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div className="techContainer">
            <div className="widthfix_10px">
                <div className="techHeadLn1">Technology</div>
                <ul className="techHeadLn_More_Blk">
                    <li className="techHeadLn2">
                        Interoperable Smart Contracts, Enhanced Token Standard & RWA
                    </li>
                    <li className="techMoreBtnBlk" style={{ display: "none" }}>
                        <Link to="#">
                            <div className="readMoreBlk">
                                <div className="readmoreText">Read More</div>
                                <div className="readMoreCircle"></div>
                            </div>
                        </Link>
                    </li>
                </ul>
                <ul className="tech_ver_col">
                    <li className="tech_ver_col">
                        <ul className="tech_HeadIconContainer">
                            <li className="tech_HeadTextBlk">
                                <div className="tectHeadText">Delivering Scalability</div>
                            </li>
                            <li className="tech_HeadIcon">
                                <div className="techIcon1"></div>
                            </li>
                        </ul>
                        <div className="techDescText">
                            Built on Layer 2 Polygon and utilizes ERC standards to represent the loans and incentives that originate from permissioned blockchain (CeFi).
                        </div>
                        <ul className="tech_points">
                            <li className="tech_points"></li>
                            <li className="tech_points">ERC 721 - Unique & indivisible Non-fungible tokens (NFTs) reflect loans</li>
                            <li className="tech_points"></li>
                            <li className="tech_points">ERC 20 - Fungible tokens on Ethereum and EVM-based blockchains used to build PAZA & XPAZA utility tokens</li>
                            {/* <li className="tech_points"></li>
                            <li className="tech_points">Interchain Communication Protocols</li>
                            <li className="tech_points"></li>
                            <li className="tech_points">Regulatory and Legal Considerations</li> */}
                        </ul>
                        {/* <input type="button" className="but_tech" value="Know More" /> */}
                    </li>
                    <li className="tech_ver_col">
                        <ul className="tech_HeadIconContainer">
                            <li className="tech_HeadTextBlk">
                                <div className="tectHeadText">Interoperability &  Efficient</div>
                            </li>
                            <li className="tech_HeadIcon">
                                <div className="techIcon2"></div>
                            </li>
                        </ul>
                        <div className="techDescText">
                            One of the firsts to achieve efficient connection between two blockchains i.e. the permissioned Hyperledger Fabric (CeFi) and public chain on Polygon (DeFi).
                        </div>
                        <ul className="tech_points">
                            <li className="tech_points"></li>
                            <li className="tech_points">Gasless Meta Transaction - perform transactions on the public chain without the need to pay gas fees in the native cryptocurrency</li>
                            <li className="tech_points"></li>
                            <li className="tech_points">Interoperability - connect and share data between permissioned & public chains.</li>
                            <li className="tech_points"></li>
                            <li className="tech_points">Highly Optimized Smart Contracts (SC) - minimum transaction fees, high execution speed, and minimized resource usage</li>
                        </ul>
                        {/* <input type="button" className="but_tech" value="Know More" /> */}
                    </li>
                    <li className="tech_ver_col">
                        <ul className="tech_HeadIconContainer">
                            <li className="tech_HeadTextBlk">
                                <div className="tectHeadText">Security</div>
                            </li>
                            <li className="tech_HeadIcon">
                                <div className="techIcon3"></div>
                            </li>
                        </ul>
                        <div className="techDescText">
                            One of the firsts to achieve efficient connection between two blockchains i.e. the permissioned Hyperledger Fabric (CeFi) and public chain on Polygon (DeFi).
                        </div>
                        <ul className="tech_points">
                            <li className="tech_points"></li>
                            <li className="tech_points">Smart Contracts audited and certified by CertiK</li>
                        </ul>
                        {/* <input type="button" className="but_tech" value="Know More" /> */}
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Component;