import { React } from "../../../common";
import Container from "../../container";

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className="commonPageHeader">
                            <div className="pgHeadText">Ipazalabs</div>
                            <div className="pgHeadDescText">NEW WORLD. NEW DREAM.</div>
                        </div>
                        <div className="widthfix_10px">
                            <div className="leadership">
                                <div className="leadershipBlkTopTxt">We believe that collaboration is at the heart of the home mortgage ecosystem —but how do you collaborate when everyone is looking over your shoulder? Now, it's easier than ever to work together by leveraging. Istakapaza's permissioned blockchain technology to help ensure that your home mortgage is safe from fraud & abuse. Additionally, Istakapaza provides you a competitive edge in terms of 2x faster processing, 250 bps cost savings & counting more..!
                                </div>
                                <ul className="leadershipIIIcol">
                                    <li className="leadershipIIIcol">
                                        <div className="leadershipImgAlok"></div>
                                        <div className="leadershipName">Alok K Sinha</div>
                                        <div className="leadershipDesg">Founder &amp; Chief Ecosystem Officer</div>
                                    </li>
                                    <li className="leadershipIIIcol">
                                        <div className="leadershipImgSujay"></div>
                                        <div className="leadershipName">Sujay Kanth</div>
                                        <div className="leadershipDesg">Founder &amp; Joint Ecosystem Officer</div>
                                    </li>
                                    <li className="leadershipIIIcol">
                                        <div className="leadershipImgGopal"></div>
                                        <div className="leadershipName">Gopal Anandan</div>
                                        <div className="leadershipDesg">Chief Operating Officer</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;