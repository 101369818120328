import { React, Header, Footer } from "../common";

const Component = ({ children, headerType, footerType }) => {
    return (
        <>
            <Header type={headerType} />
            {children}
            <Footer type={footerType} />
        </>
    );
};

export default Component;