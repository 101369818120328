import { React, Slider, Link } from "../../../common";

import Mp4Video from "../video/clouds.mp4";
import WebmVideo from "../video/clouds.webm";

const SliderComponent = ({ children }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return <Slider {...settings}>{children} </Slider>
}

const Component = () => {

  return (
    <>
      <div id="homeVideo">
        <div className="videoContainer">
          <ul className="videoBG">
            <li className="videoBG"></li>
            <li className="videoBG"></li>
          </ul>
          <video className="video1-bg" autoPlay={true} loop muted style={{ width: '100%' }}>
            <source src={Mp4Video} />
            <source src={WebmVideo} />
            Video not supported.
          </video>
        </div>
        <div className="fallback_smallscreen"></div>

        <div className="videoContent_blk">
          <div className="widthfix_corousel">
            <SliderComponent>
              {/* First Slide - Generic */}
              <div className="widthfix_corousel_content">
                <ul className="homeText_Quote_blk">
                  <li className="homeText">
                    <div className="homeMainTxtBlock">
                      <h1>
                        <div className="mainTxt_line">Unlock your dream home</div>
                        <div className="mainTxt_line">with PAZA tokens.</div>
                        <div className="mainTxt_line">Revolutionizing mortgages.</div>
                      </h1>
                    </div>
                    <div className="secondaryTxt">
                      <div className="secondaryTxt_line">Contact your broker to get free PAZAs.</div>
                    </div>
                    <div className="homeButLabel">
                      <Link to="#"><input
                        type="submit"
                        className="but_learnMore"
                        value="Learn More"
                      /></Link>
                    </div>
                  </li>
                  <li className="homeCurveArtwork">
                    <div className="artworkHome"></div>
                  </li>
                </ul>
              </div>
              {/* Second Slide */}
              <div className="widthfix_corousel_content">
                <ul className="homeText_Quote_blk">
                  <li className="homeText">
                    <div className="homeMainTxtBlock">
                      <h1>
                        <div className="mainTxt_line">Purchase or Refinance - </div>
                        <div className="mainTxt_line">Get rewarded. Pay for closing</div>
                        <div className="mainTxt_line">costs / services with PAZAs</div>
                      </h1>
                    </div>
                    <div className="secondaryTxt">
                      <div className="secondaryTxt_line">Transact, borrow and lend</div>
                      {/* <div className="secondaryTxt_line">Borrowing & Lending</div> */}
                    </div>
                    <div className="homeButLabel">
                      <Link to="/preapproval"><input
                        type="submit"
                        className="but_learnMore"
                        value="Learn More"
                      /></Link>
                    </div>
                  </li>
                  <li className="homeCurveArtwork">
                    <div className="artworkPaza"></div>
                  </li>
                </ul>
              </div>
              {/* Third Slide */}
              <div className="widthfix_corousel_content">
                <ul className="homeText_Quote_blk">
                  <li className="homeText">
                    <div className="homeMainTxtBlock">
                      <h1>
                        <div className="mainTxt_line">Loan RWA with up-to-date</div>
                        <div className="mainTxt_line">information.  </div>
                        <div className="mainTxt_line">Pool NFTs & Bonds</div>
                      </h1>
                    </div>
                    <div className="secondaryTxt">
                      <div className="secondaryTxt_line">Fractionalization of any financial asset</div>
                      {/* <div className="secondaryTxt_line">Financial Asset</div> */}
                    </div>
                    <div className="homeButLabel">
                      <Link to="/preapproval"><input
                        type="submit"
                        className="but_learnMore"
                        value="Learn More"
                      /></Link>
                    </div>
                  </li>
                  <li className="homeCurveArtwork">
                    <div className="artworkNFT"></div>
                  </li>
                </ul>
              </div>
            </SliderComponent>
          </div>
        </div >
      </div >
    </>
  );
};

export default Component;