import { React } from "../../../common";
import Container from "../../container";
import { PazaMarket } from "../../components";

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className="commonPageHeader">
                            <div className="pgHeadText">Paza Markets</div>
                            <div className="pgHeadDescText">For Multi-Vertical Centralized & Decentralized Financial Markets</div>
                        </div>
                        <div className="pazamarketContainerInpage">
                            <div className="widthfix_10px">
                                <PazaMarket />
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;