import { React } from "../../../common";
import Container from "../../container";

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container">
                    <div id="content_fullHeader">
                        <div className="commonPageHeader">
                            <div className="pgHeadText">GET CONNECTED</div>
                            <div className="pgHeadDescTextNormal">Feel free to submit your enquiries along with your contact details. <br />We will be happy to reach out to you and share more!.</div>
                        </div>

                        <div className="widthfix_10px">
                            <div className="contact">
                                <ul className="contactIIcol">
                                    <li className="contactIIcol">
                                        <div className="contactShadowBlk">
                                            <ul className="iconTextBlk">
                                                <li className="iconTextBlk">
                                                    <div className="contact_ph_icon"></div>
                                                </li>
                                                <li className="iconTextBlk">
                                                    <div className="contactTxtHead">Call us directly at:</div>
                                                    <div className="contPhEmailTxt">+1 (201) 921-8171</div>
                                                    <div className="contPhEmailTxt">+1 (321) 400-8329</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="contactShadowBlk">
                                            <ul className="iconTextBlk">
                                                <li className="iconTextBlk">
                                                    <div className="contact_email_icon"></div>
                                                </li>
                                                <li className="iconTextBlk">
                                                    <div className="contactTxtHead">Support Queries:</div>
                                                    <div className="contPhEmailTxt">info@ipaza.io</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="contactShadowBlk">
                                            <ul className="iconTextBlk">
                                                <li className="iconTextBlk">
                                                    <div className="contact_address_icon"></div>
                                                </li>
                                                <li className="iconTextBlk">
                                                    <div className="contactTxtHead">Corporate Address:</div>
                                                    <div className="contAddressTxt">Istakapaza Inc. <br />1900 Boothe Cir. Suite 100 - B<br />Longwood FL -32750</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="contactIIcol">
                                        <div className="contactShadowBlk">
                                            <div className="contactFormBlk">
                                                {/* <div className="contactFormHead">Book a Meeting</div>
                                                <div className="contactFormDesc">To schedule a meeting with one of our sales representative, please complete the form and submit. <br />Our executive will be in touch with you shortly.</div> */}
                                                <ul className="contactForm">
                                                    <li className="contactForm">
                                                        <div className="formContentHead">Name<div className="formMandatory">*</div></div>
                                                        <div className="contactTxtField"> <input type="text" /></div>
                                                    </li>
                                                    <li className="contactForm">
                                                        <div className="formContentHead">Email<div className="formMandatory">*</div></div>
                                                        <div className="contactTxtField"><input type="email" /></div>
                                                    </li>
                                                    <li className="contactForm">
                                                        <div className="formContentHead">Phone Number<div className="formMandatory">*</div></div>
                                                        <div className="contactTxtField"><input type="text" /></div>
                                                    </li>
                                                    <li className="contactForm">
                                                        <div className="formContentHead">Address</div>
                                                        <div className="contactTxtField"><input type="text" /></div>
                                                    </li>
                                                    <li className="contactFormSingle">
                                                        <div className="formContentHead">Inquiry Details<div className="formMandatory">*</div></div>
                                                        <div className="contactTxtField"><textarea></textarea></div>
                                                    </li>
                                                </ul>
                                                <input type="button" className="contact_button" value="Submit" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;