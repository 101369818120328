import { React, Link, useLocation } from "../../common";
import Authentication from "../auth";
import UseTimerSession from "../../helper/useTimerSession";

// PAZA Market
const mnu1 = ["/market"];
// About Us
const mnu2 = ["/about"];
// Leadership
const mnu3 = ["/leadership"];
// Contact Us
const mnu4 = ["/contact"];

// Downloads
const mnu5 = ["/download"];

// PreLogin Homepage Header
const LoggedOutHeader = (props) => {
  const { loc } = props;

  const IsActive = (mnu) => {
    return mnu.findIndex((x) => x === loc) > -1 ? "dropbtn active" : "dropbtn";
  };

  return (
    <>
      <header className='header'>
        <div className='widthfix_10px'>
          <div className='logo'>
            <Link to='/'>
              <div className='ipazalabsLogo logoPositionHome'></div>
            </Link>
          </div>

          <div className='headerTopRight'>
            <div className='login_btnLabel'>
              <input
                type='button'
                className='login_button'
                value='Login'
                onClick={() =>
                  (window.location.href = "https://istakapaza.us/#/login")
                }
              />
            </div>

            <div className='topnav'>
              {/* <div className='dropdown'>
                <Link to='/market'>
                  <button className={IsActive(mnu1)}>PAZA Markets</button>
                </Link>
              </div>
              <div className='dropdown'>
                <Link to='/about'>
                  <button className={IsActive(mnu2)}>About</button>
                </Link>
              </div>
              <div className='dropdown'>
                <Link to='/leadership'>
                  <button className={IsActive(mnu3)}>Leadership</button>
                </Link>
              </div>
              <div className='dropdown'>
                <Link to='/contact'>
                  <button className={IsActive(mnu4)}>Contact</button>
                </Link>
              </div>
              <div className='dropdown'>
                <Link to='/download'>
                  <button className={IsActive(mnu5)}>Downloads</button>
                </Link>
              </div> */}
              <div className='dropdown'>
                <a
                  href='https://vesting.ipaza.io/'
                  target={"_blank"}
                  rel='noopener noreferrer'>
                  <button className={IsActive(mnu5)}>PAZA World</button>
                </a>
              </div>
              {/* <Link to="#" className="icon" onClick={() => window.myFunction()}></Link> */}
            </div>
          </div>
        </div>
      </header>

      <header className='headerResponsive'>
        <div className='navBlockResponsive'>
          <div className='loginBlkResponsive'>
            <Link
              to='#'
              onClick={() =>
                (window.location.href = "https://istakapaza.us/#/login")
              }>
              <div className='LoginLogoutBtnIcon'></div>
              <div className='loginLogoutBtnTxt'>Login</div>
            </Link>
          </div>

          <div className='topnav' id='myTopnav'>
            <Link to='/'>
              <div className='logoPosResponsive ipazalabsLogo'></div>
            </Link>
            {/* <Link to='/market'>
              <div className='dropdown'>
                <button className={IsActive(mnu1)}>PAZA Markets</button>
              </div>
            </Link>

            <Link to='/about'>
              <div className='dropdown'>
                <button className={IsActive(mnu2)}>About Us</button>
              </div>
            </Link>

            <Link to='/leadership'>
              <div className='dropdown'>
                <button className={IsActive(mnu3)}>Leadership</button>
              </div>
            </Link>

            <Link to='/contact'>
              <div className='dropdown'>
                <button className={IsActive(mnu4)}>Contact Us</button>
              </div>
            </Link>

            <Link to='/download'>
              <div className='dropdown'>
                <button className={IsActive(mnu4)}>Downloads</button>
              </div>
            </Link> */}

            <a
              href='https://vesting.ipaza.io/'
              target={"_blank"}
              rel='noopener noreferrer'>
              <button className={IsActive(mnu4)}>PAZA World</button>
            </a>

            <Link
              to='#'
              className='icon'
              onClick={() => window.myFunction()}></Link>
          </div>
        </div>
      </header>
    </>
  );
};

// PreLogin Inpage Header
const LoggedOutFullHeader = (props) => {
  const { loc } = props;

  const IsActive = (mnu) => {
    return mnu.findIndex((x) => x === loc) > -1 ? "dropbtn active" : "dropbtn";
  };

  return (
    <>
      <header className='headerInpage'>
        <div className='widthfix_10px'>
          <div className='logo'>
            <Link to='/'>
              <div className='ipazalabsLogo logoPosInpage'></div>
            </Link>
          </div>

          <div className='headerTopRightInpage'>
            <div className='login_btnLabel'>
              <input
                type='button'
                className='login_button'
                value='Login'
                onClick={() =>
                  (window.location.href = "https://istakapaza.us/#/login")
                }
              />
            </div>

            <div className='topnav'>
              {/* <div className='dropdown'>
                <Link to='/market'>
                  <button className={IsActive(mnu1)}>PAZA Markets</button>
                </Link>
              </div>
              <div className='dropdown'>
                <Link to='/about'>
                  <button className={IsActive(mnu2)}>About</button>
                </Link>
              </div>
              <div className='dropdown'>
                <Link to='/leadership'>
                  <button className={IsActive(mnu3)}>Leadership</button>
                </Link>
              </div>
              <div className='dropdown'>
                <Link to='/contact'>
                  <button className={IsActive(mnu4)}>Contact</button>
                </Link>
              </div>
              <div className='dropdown'>
                <Link to='/download'>
                  <button className={IsActive(mnu5)}>Downloads</button>
                </Link>
              </div> */}
              <div className='dropdown'>
                <a
                  href='https://vesting.ipaza.io/'
                  target={"_blank"}
                  rel='noopener noreferrer'>
                  <button className={IsActive(mnu5)}>PAZA World</button>
                </a>
              </div>
              {/* <Link to="#" className="icon" onClick={() => window.myFunction()}></Link> */}
            </div>
          </div>
        </div>
      </header>

      <header className='headerResponsive'>
        <div className='navBlockResponsive'>
          <div className='loginBlkResponsive'>
            <Link
              to='#'
              onClick={() =>
                (window.location.href = "https://istakapaza.us/#/login")
              }>
              <div className='LoginLogoutBtnIcon'></div>
              <div className='loginLogoutBtnTxt'>Login</div>
            </Link>
          </div>

          <div className='topnav' id='myTopnav'>
            <Link to='/'>
              <div className='ipazalabsLogo logoPosResponsive'></div>
            </Link>
            {/* <Link to='/market'>
              <div className='dropdown'>
                <button className={IsActive(mnu1)}>PAZA Markets</button>
              </div>
            </Link>

            <Link to='/about'>
              <div className='dropdown'>
                <button className={IsActive(mnu2)}>About Us</button>
              </div>
            </Link>

            <Link to='/leadership'>
              <div className='dropdown'>
                <button className={IsActive(mnu3)}>Leadership</button>
              </div>
            </Link>

            <Link to='/contact'>
              <div className='dropdown'>
                <button className={IsActive(mnu4)}>Contact Us</button>
              </div>
            </Link>

            <Link to='/download'>
              <div className='dropdown'>
                <button className={IsActive(mnu5)}>Downloads</button>
              </div>
            </Link> */}

            <a
              href='https://vesting.ipaza.io/'
              target={"_blank"}
              rel='noopener noreferrer'>
              <div className='dropdown'>
                <button className={IsActive(mnu5)}>PAZA World</button>
              </div>
            </a>

            <Link
              to='#'
              className='icon'
              onClick={() => window.myFunction()}></Link>
          </div>
        </div>
      </header>
    </>
  );
};

// Post Login Header
const LoggedInHeader = (props) => {
  // const [fullName, setFullName] = React.useState("Hello, Sign In");
  // const NavigateTo = useNavigate();

  // React.useEffect(() => {
  //   const fn = () => {
  //     let user = sessionStorage.getItem("user");
  //     setFullName(user);
  //   };
  //   fn();
  // }, []);

  // const OnSignOutClicked = async (e) => {
  //   e.preventDefault();
  //   sessionStorage.removeItem("user");
  //   NavigateTo("/");
  // };

  return <></>;
};

const Component = (props) => {
  const { type } = props;
  const [user] = UseTimerSession("user");
  const loc = useLocation().pathname;
  return (
    <>
      {user ? (
        <LoggedInHeader loc={loc} {...props} />
      ) : type === "inner" ? (
        <LoggedOutFullHeader loc={loc} {...props} />
      ) : (
        <LoggedOutHeader loc={loc} {...props} />
      )}
      <Authentication />
    </>
  );
};

export default Component;
